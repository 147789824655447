import { dateFormat, formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr, amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'
import QRCode from 'react-qr-code'
import React from 'react'

export const ELetterServiceAP = ({
  docType,
  previewDetail,
  allpreviewDetail,
  paramVal,
  ItemIndex,
}) => {
  let APRefund, APInvoice

  const creditorDetails = {
    '[CreditorName]':
      previewDetail?.CreditorAccount?.CompanyName! ??
      previewDetail?.CreditorAccount?.DebtorName!,
    '[CreditorAddress]': `${
      previewDetail?.CreditorAccount?.Address?.address
    }<br />
          ${
            previewDetail?.CreditorAccount?.Address?.country == 'Singapore'
              ? `${previewDetail?.CreditorAccount?.Address?.country} ${previewDetail?.CreditorAccount?.Address?.postCode}`
              : `${previewDetail?.CreditorAccount?.Address?.postCode} ${previewDetail?.CreditorAccount?.Address?.city}, 
            ${previewDetail?.CreditorAccount?.Address?.state}, ${previewDetail?.CreditorAccount?.Address?.country}`
          }
           <br />`,
    '[CreditorContactName]':
      previewDetail?.CreditorAccount?.CreditorContact[0]?.Name! ?? '',
    '[CreditorContactNo]':
      previewDetail?.CreditorAccount?.CreditorContact[0]?.ContactNo! ?? '',
    '[CreditorContactEmail]':
      previewDetail?.CreditorAccount?.CreditorContact[0]?.Email! ?? '',
    '[CreditorCompanyRegNo]':
      previewDetail?.CreditorAccount?.CompanyRegNo! ?? '',
    '[CreditorBankAccName]': previewDetail?.CreditorAccount?.BankName! ?? '',
    '[CreditorBankAccNo]': previewDetail?.CreditorAccount?.BankAccountNo! ?? '',
    '[CreditorTIN]': previewDetail?.CreditorAccount?.einvoice_TIN! ?? '',
    '[CreditorSSTRegNo]': previewDetail?.SSTRegNo! ?? '',
    '[CreditorGSTRegNo]': previewDetail?.GSTRegNo! ?? '',
    '[QRCode]':
      previewDetail?.is_einvoice && previewDetail?.einvoice_qr_url ? (
        <QRCode
          size={128}
          style={{
            height: 'auto',
            maxWidth: '100%',
            width: '100%',
          }}
          value={previewDetail?.einvoice_qr_url}
          viewBox={`0 0 128 128`}
        />
      ) : (
        ''
      ),
  }

  switch (docType) {
    case 'payment':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[ReferenceNo]': previewDetail?.RefNo!,
        '[Description]': previewDetail?.Description!,
        '[PaymentMethod]': previewDetail?.PaymentMethod?.Name! ?? '', //to be changed
        '[ChequeNo]': previewDetail?.ChequeNo! ?? '',
        '[CompanyBankAccCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[CompanyBankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
        // Item Info
        items: previewDetail?.Allocation?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[AllocationDocDate]': el?.CreditDocDate
              ? dateFormat(el?.CreditDocDate!)
              : '',
            '[AllocationDocNo]': el?.CreditDocNo! ?? '',
            '[AllocationDescription]': el?.Description!,
            '[AllocationAmt]': amtStr(el?.AllocationAmt!),
          }
        }),
        '[SumAllocationAmt]': amtStr(
          allpreviewDetail?.Allocation?.reduce(
            (a, b) => a + Number(b?.AllocationAmt!),
            0
          )
        ),
      })

    case 'advance':
      return Object.assign(paramVal, creditorDetails, {
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[ReferenceNo]': previewDetail?.RefNo!,
        '[Description]': previewDetail?.Description!,
        '[TotalAmt]': amtStr(previewDetail?.DocAmt!),
        '[SumTotalAmt]': amtStr(previewDetail?.DocAmt!),
        '[PaymentMethod]': previewDetail?.PaymentMethod?.Name! ?? '', //to be changed
        '[ChequeNo]': previewDetail?.ChequeNo! ?? '',
        '[CompanyBankAccCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[CompanyBankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
      })

    case 'refund':
      return Object.assign(paramVal, APRefund)

    case 'invoice':
      return Object.assign(paramVal, APInvoice)

    case 'credit-note':
      return Object.assign(paramVal, creditorDetails, {
        // Left Header info
        '[Description]': previewDetail?.Description! ?? '',
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[ReferenceNo]': previewDetail?.RefNo!,
        // Item Listing
        items: previewDetail?.APCreditNoteItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[ItemRemark]': el?.Remark!,
            '[TaxRate]': el?.TaxRate! + '%',
            '[BaseAmt]': amtStr(el?.Amount!),
            '[TaxAmt]': amtStr(el?.TaxAmt!),
            '[TotalAmt]': amtStr(el?.DocAmt!),
          }
        }),
        // Item Listing
        allocItems: previewDetail?.Allocation?.map((el, i) => {
          return {
            '[AllocIndex]': i + 1,
            '[AllocDocDate]': dateFormat(el?.CreditDocDate!),
            '[AllocDocNo]': el?.CreditDocNo!,
            '[AllocDesc]': el?.Description!,
            '[AllocAmt]': amtStr(el?.AllocationAmt!),
          }
        }),
        // Total Amount Footer
        '[SumBaseAmt]': allpreviewDetail?.APCreditNoteItem?.reduce(
          (a, b) => a + Number(b?.Amount),
          0
        ).toFixed(2),
        '[SumTaxAmt]': amtStr(
          allpreviewDetail?.APCreditNoteItem?.reduce(
            (a, b) => a + Number(b?.TaxAmt),
            0
          ).toFixed(2)
        ),
        '[SumTotalAmt]': amtStr(
          allpreviewDetail?.APCreditNoteItem?.reduce(
            (a, b) => a + Number(b?.DocAmt),
            0
          ).toFixed(2)
        ),
      })

    case 'debit-note':
      return Object.assign(paramVal, creditorDetails, {
        // Left Header info
        '[Description]': previewDetail?.Description! ?? '',
        // Right Header Info
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!),
        '[ReferenceNo]': previewDetail?.RefNo!,
        // Item Listing
        items: previewDetail?.APDebitNoteItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[ItemRemark]': el?.Remark!,
            '[TaxRate]': el?.TaxRate! + '%',
            '[BaseAmt]': amtStr(el?.Amount!),
            '[TaxAmt]': amtStr(el?.TaxAmt!),
            '[TotalAmt]': amtStr(el?.DocAmt!),
          }
        }),
        // Total Amount Footer
        '[SumBaseAmt]': allpreviewDetail?.APDebitNoteItem?.reduce(
          (a, b) => a + Number(b?.Amount),
          0
        ).toFixed(2),
        '[SumTaxAmt]': amtStr(
          allpreviewDetail?.APDebitNoteItem?.reduce(
            (a, b) => a + Number(b?.TaxAmt),
            0
          ).toFixed(2)
        ),
        '[SumTotalAmt]': amtStr(
          allpreviewDetail?.APDebitNoteItem?.reduce(
            (a, b) => a + Number(b?.DocAmt),
            0
          ).toFixed(2)
        ),
      })
  }
}
