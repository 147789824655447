import { useGetApprovalLogLazyQuery } from '@account-root/procurement-react/src/generated/graphql'
import theme from '@ifca-root/react-component/src/assets/theme'
import { CardContents } from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  dateFormat,
  formatDate,
} from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate'
import {
  Divider,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab'
import { ApprovalStatus, useGetUserLazyQuery } from 'generated/graphql'
import { formatTime24Hour } from 'helpers/StringNumberFunction/FormatDate'
import { toProperCase } from 'helpers/StringNumberFunction/StringConverter'
import React, { useEffect } from 'react'

export interface ApprovalLogProps {
  data?: any
  setWorkFlow?: any
  openWorkFlow?: boolean
  moduleName?: string
  reset?: any
  handleClose?: any
}

export const ApprovalLogDialog = (props: ApprovalLogProps) => {
  const {
    data,
    setWorkFlow,
    openWorkFlow,
    moduleName,
    reset,
    handleClose,
  } = props

  const [
    loadApprovalLog,
    { loading: logLoading, data: { getApprovalLog } = { getApprovalLog: [] } },
  ] = useGetApprovalLogLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getApprovalLog }) => {
      if (getApprovalLog?.length === 0) {
        lazyGetUser({
          variables: {
            ID: data?.obj?.submittedBy
              ? data?.obj?.submittedBy
              : data?.obj?.createdBy,
          },
        })
      }
    },
  })

  const CustomTimelineItem = withStyles({
    missingOppositeContent: {
      '&:before': {
        display: 'none',
      },
    },
  })(TimelineItem)

  const [
    lazyGetUser,
    {
      loading: userLoading,
      data: { getUser } = {
        getUser: {
          ID: '',
          commonStatus: '',
          contactNo: '',
          department: '',
          email: '',
          jobTitle: '',
          name: '',
          superUser: false,
          userName: '',
        },
      },
    },
  ] = useGetUserLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    if (data?.ID) {
      loadApprovalLog({ variables: { RefID: data?.ID } })
    }
  }, [data?.ID])

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  return (
    <>
      {logLoading && <Loading />}
      {userLoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={openWorkFlow}
        onClose={() => {
          setWorkFlow(false)
          reset()
          handleClose()
        }}
        sections={{
          header: {
            title: moduleName,
            rightText: 'Approval Log',
            infoLine: data?.obj?.DocNo,
            rightInfoLine: formatDate(data?.obj?.createdTs),
          },
          body: () => (
            <>
              {getApprovalLog?.length === 0 ? (
                <CardContents
                  contentStyle={{ padding: '4px 12px' }}
                  style={{
                    boxShadow:
                      '0 3px 1px -2px rgb(0 0 0 / 0%), 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0px 5px 0 rgb(0 0 0 / 10%)',
                  }}
                >
                  <div
                    className="content-wrap full"
                    style={{
                      margin: '0px',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {`Submitter`}
                    </Typography>
                    <PersonIcon style={{ fontSize: 'small' }} />
                    <Typography variant="caption">{getUser?.name}</Typography>
                    <br />
                    <EventIcon
                      style={{
                        fontSize: 'small',
                        paddingRight: '8px',
                        marginBottom: '-3px',
                      }}
                    />
                    <Typography variant="caption">
                      {dateFormat(data?.obj?.createdTs)}
                      &nbsp;&nbsp;
                      {formatTime24Hour(data?.obj?.createdTs)}
                    </Typography>
                  </div>
                </CardContents>
              ) : (
                getApprovalLog?.map((el, index) => (
                  <>
                    <CardContents
                      contentStyle={{ padding: '4px 12px 0px' }}
                      style={{
                        boxShadow:
                          '0 3px 1px -2px rgb(0 0 0 / 0%), 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0px 5px 0 rgb(0 0 0 / 10%)',
                      }}
                    >
                      <div
                        className="content-wrap full"
                        style={{
                          margin: '0px',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                        >
                          {`Submitter`}
                        </Typography>
                        <PersonIcon style={{ fontSize: 'small' }} />
                        <Typography variant="caption">
                          {el?.Name ?? getUser?.name}
                        </Typography>
                        <br />
                        <EventIcon
                          style={{
                            fontSize: 'small',
                            paddingRight: '8px',
                            marginBottom: '-3px',
                          }}
                        />
                        <Typography variant="caption">
                          {dateFormat(el?.SubmitDate ?? data?.obj?.createdTs)}
                          &nbsp; &nbsp;
                          {formatTime24Hour(
                            el?.createdTs ?? data?.obj?.createdTs
                          )}
                        </Typography>
                        <Divider
                          orientation="horizontal"
                          variant="fullWidth"
                          style={{
                            color: 'grey',
                            backgroundColor: 'grey',
                            marginTop: '10px',
                            width: '115%',
                            marginLeft: '-15px',
                          }}
                        />
                        <Timeline className="timeline">
                          {el?.WorkflowStep?.map(v => (
                            <CustomTimelineItem>
                              <TimelineSeparator>
                                <TimelineDot
                                  style={{
                                    backgroundColor:
                                      el?.ApproveStatus ===
                                      ApprovalStatus.Pending
                                        ? 'grey'
                                        : '#ff9800',
                                  }}
                                />
                                {index !== el?.WorkFlowStep?.length - 1 ? (
                                  <TimelineConnector />
                                ) : null}
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography variant="subtitle1">
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {`Level ${v?.StepNo}`}
                                  </span>
                                  &nbsp;
                                  <span
                                    style={
                                      v?.StepApprovalStatus ===
                                      ApprovalStatus.Pending
                                        ? {
                                            color: '#2f53e6',
                                            fontSize: '12px',
                                          }
                                        : v?.StepApprovalStatus ===
                                          ApprovalStatus.Rejected
                                        ? {
                                            color: 'red',
                                            fontSize: '12px',
                                          }
                                        : {
                                            color: '#00bf0f',
                                            fontSize: '12px',
                                          }
                                    }
                                  >
                                    {`${toProperCase(v?.ApprovalStatus)} ${
                                      v?.ApprovalStatus ===
                                      ApprovalStatus.Pending
                                        ? 'Approval'
                                        : ''
                                    }`}
                                  </span>
                                </Typography>

                                <>
                                  <PersonIcon
                                    style={{
                                      fontSize: 'small',
                                      marginBottom: '-2px',
                                      paddingRight: '5px',
                                    }}
                                  />
                                  <Typography variant="caption">
                                    {`${v?.RoleName} ${
                                      v?.Name ? `| ${v?.Name}` : ''
                                    }`}
                                  </Typography>
                                  {!!v?.ApprovalDT && (
                                    <>
                                      <br />
                                      <Typography variant="caption">
                                        {v?.ApprovalDT === null
                                          ? ''
                                          : dateFormat(v?.ApprovalDT)}
                                        &nbsp; &nbsp;
                                        {v?.StepApprovalStatus ===
                                        ApprovalStatus.Pending
                                          ? ''
                                          : formatTime24Hour(v?.ApprovalDT)}
                                      </Typography>
                                    </>
                                  )}
                                  <br />
                                  {v?.ApprovalStatus ===
                                  ApprovalStatus.Rejected ? (
                                    <>
                                      <Typography variant="caption">
                                        {`Reject Remarks : ${v?.StatusRemarks}`}
                                      </Typography>
                                    </>
                                  ) : null}
                                </>
                              </TimelineContent>
                            </CustomTimelineItem>
                          ))}
                        </Timeline>
                      </div>
                    </CardContents>
                  </>
                ))
              )}
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setWorkFlow(false)
                    reset()
                    handleClose()
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
